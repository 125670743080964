
<template>
  <div >
    <Header v-if="showHeader" />
      <!--  Modals -->
      <router-view :key="$route.path" />

    <Footer v-if="showFooter" />
  </div>

</template>

<script>
import Footer from '@/components/layouts/Footer.vue'
import Header from '@/components/layouts/Header.vue'

export default {
  name: 'App',
  components: {
    Footer,
    Header
  },
  data() {
    return {
      showHeader: false,
      showFooter: false,
      routes:[],
    }
  },
}
</script>

<style >
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  overflow-x: hidden;
  overflow-y: hidden;
}


</style>


