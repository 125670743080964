<template>
  <div>
    <h1>footer</h1>
  </div>
</template>

<script>
export default {
 
}
</script>