<template>
    <div class="logo-top">
          <div class="logo-left">
              <img :src="require('@/assets/images/logo-img.png')" alt="imageName">
              <img :src="require('@/assets/images/logo-name-1.png')">
          </div>
          <p>Admin</p>
          <div class="admin">
              <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" href="#" @click=logout()>Logout</a>
                  </div>
              </div>
              <a href="#">MS</a>
          </div>
      </div>

</template>

<script>


export default {
  components: {
  },
  props: {
    fade: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },

  methods: {
    async logout() {
      await this.$store.dispatch("logout");
      this.$router.push('/')
    },
  
  },
};
</script>

