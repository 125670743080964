import Vue from 'vue'
import App from './App.vue'
import router from './utils/router'
import store from './utils/store'
import '@/assets/css/style.css'
import Toasted from 'vue-toasted';
import Vuex from 'vuex'
import VueConfirmDialog from 'vue-confirm-dialog'
import '@/utils/commonFunction'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import VueLazyLoad from 'vue-lazyload'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'






Vue.use(CoolLightBox)
Vue.use(VueLazyLoad)
Vue.use(VueTelInput);
Vue.component('v-select', vSelect)
Vue.use(Vuex)
Vue.use(Toasted, { 
  position: "top-right", 
  duration : 3000
});

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)



Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')





