import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({

    state: {
        user_data:{},
        access_token:null,
        refresh_token:null,
        email :null,
        brand:null,
        brand_name:null
        
    },
    mutations: {
        setUserData(state, user_data) {
            state.user_data = user_data
        },
        setAccessToken(state, access_token) {
            state.access_token = access_token
        },
        setRefreshToken(state, refresh_token) {
            state.refresh_token = refresh_token
        },
        setEmail(state, email) {
            state.email = email
        },
        setBrand(state, brand) {
            state.brand = brand
        },
        setBrandName(state, brand_name) {
            state.brand_name = brand_name
        },
        logout(state){
            state.user_data = {},
            state.access_token = null
            state.refresh_token = null
        },
        
    },
    actions: {
    },
    getters: {
        isLoggedIn: state => !!state.access_token,
        user_data: state => {
            return state.user_data
        },
        refreshToken:state =>{
            return state.refresh_token

        }



    },




})