import Vue from 'vue'
import store from '@/utils/store'
import router from '@/utils/router'

const axios = require('axios').default;
const baseUrl =  process.env.VUE_APP_BASE_URL || 'http://192.168.0.159:8080'
const plugin = {
    install(vue) {
        Vue.prototype.$RefreshToken=() =>{
                console.log('-----------------got into refresh token common function-----------------------------')
                const { getters } = vue.prototype.$myStore;
                console.log('hitting refresh tokenapi ')
                const data =  { "refresh": getters.refreshToken };
                const headers = { 
                    "Content-Type": "application/json",
                };
                axios.post(`${baseUrl}/auth/refresh-token`, data,{headers})
                .then(response => {
                    console.log('token refreshed',response)
                    console.log(response.data)
                    if(response.status==200){
                        vue.prototype.$toasted.success("login Successful");
                        vue.prototype.$myStore.commit("setUserData",response.data.user_data);
                        vue.prototype.$myStore.commit("setAccessToken", response.data.access);
                        vue.prototype.$myStore.commit("setRefreshToken", response.data.refresh);
                        vue.prototype.$myRouter.push('/brands-listing')
                    }
                    else {
                        vue.prototype.$toasted.error('Something went wrong! try logging in again')
                        vue.prototype.$myRouter.push('/')
                    }
                })
                .catch(e => {
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        vue.prototype.$toasted.error(e.response.data.errors[i].message)
                    }
                    vue.prototype.$myStore.dispatch("logout");
                    vue.prototype.$myRouter.push('/')                    
                })
                
        }

  },
}
 
Vue.use(plugin);
Vue.prototype.$myStore = store
Vue.prototype.$myRouter = router