import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    // User Routes
    {
      path: '/',
      name: 'login',
      component: () => import('@/components/auth/Login.vue'),
      meta:{
        guest: true,
      },
    },
    {
      path: '/change-password',
      name: 'changePassword',
      component: () => import('@/components/auth/ChangePassword.vue'),
      meta:{
        guest: true,
      },
    },
    {
      path: '/request-reset-password',
      name: 'requestResetPassword',
      component: () => import('@/components/auth/requestResetPassword.vue'),
      meta:{
        guest: true,
      },
    },
    {
      path: '/reset-password-blank',
      name: 'resetPasswordBlank',
      component: () => import('@/components/auth/ResetPasswordBlank.vue'),
      meta:{
        guest: true,
      },
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: () => import('@/components/auth/ResetPassword.vue'),
      meta:{
        guest: true,
      },
    },


    // brands routes

    
    {
      path: '/brands-listing',
      name: 'BrandsListing',
      component: () => import('@/components/brands/BrandsListing.vue'),
      meta:{
        requiresAuth: true,
      },
    },
    {
      path: '/brands-default',
      name: 'BrandsDefault',
      component: () => import('@/components/brands/BrandsDefault.vue'),
      meta:{
        requiresAuth: true,
      },
    },
    {
      path: '/brands-intro',
      name: 'BrandsIntro',
      component: () => import('@/components/brands/BrandsIntro.vue'),
      meta:{
        requiresAuth: true,
      },
    },
    {
      path: '/brands-contact',
      name: 'brandsContact',
      component: () => import('@/components/brands/BrandsContact.vue'),
      meta:{
        requiresAuth: true,
      },
    },
    {
      path: '/brands-menu',
      name: 'BrandsMenu',
      component: () => import('@/components/brands/BrandsMenu.vue'),
      meta:{
        requiresAuth: true,
      },
    },
    {
      path: '/add-menu-item/:id',
      name: 'AddMenuItem',
      component: () => import('@/components/brands/AddMenuItem.vue'),
      meta:{
        requiresAuth: true,
      },
    },
    {
      path: '/edit-menu-item/:id',
      name: 'EditMenuItem',
      component: () => import('@/components/brands/EditMenuItem.vue'),
      meta:{
        requiresAuth: true,
      },
    },
    {
      path: '/fee-and-promos',
      name: 'FeeAndPromos',
      component: () => import('@/components/brands/FeeAndPromos.vue'),
      meta:{
        requiresAuth: true,
      },
    },
    {
      path: '/brands-notice',
      name: 'BrandsNotice',
      component: () => import('@/components/brands/BrandsNotice.vue'),
      meta:{
        requiresAuth: true,
      },
    },
    {
      path: '/brands-schedule',
      name: 'BrandsSchedule',
      component: () => import('@/components/brands/BrandsSchedule.vue'),
      meta:{
        requiresAuth: true,
      },
    },



    {
      path: '*',
      name: 'PageNotFound',
      component: () => import('@/pages/PageNotFound.vue'),
      meta:{
        guest: true,
      },
    },
  ],

})

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.guest)) {
        next()
    } 
    else if(to.matched.some(record => record.meta.requiresAuth)){
      console.log('requires login')
        if (!store.getters.isLoggedIn) {
          console.log('not logged in ')
            // If not loggedIn redirect to homepage
            next('/')
        } 
        else {
          console.log('logged in, so go')
            next()
        }
    }
})




export default router










